"use client";
import { useEffect } from "react";
import Loading from "../components/loading";
import { allPermissions } from "../data/data";
import { USER } from "../data/globalVariables";
import PageRoot from "./pageRoot";

export default function Home() {
  const user = USER.useSubscribe(`homeUser`);
  useEffect(() => {
    if (user?.role?.id) {
      const permissionInfo =
        allPermissions[user.role.initialPage || "ecgsPage"];
      window.location.href = permissionInfo?.href || "/profile";
    }
  }, [user?.role?.id, user?.role?.initialPage]);

  return (
    <PageRoot>
      <div className="c1 center p20" style={{ height: "100vh" }}>
        <Loading color="blue"></Loading>
      </div>
    </PageRoot>
  );
}
