import { BiInjection, BiTestTube } from "react-icons/bi";
import { CgFileDocument, CgProfile } from "react-icons/cg";
import {
  FaListUl,
  FaRegChartBar,
  FaRegFolderOpen,
  FaStethoscope,
} from "react-icons/fa";
import { FiPieChart, FiUsers } from "react-icons/fi";
import {} from "react-icons/pi";

import { HiOutlineDocumentText } from "react-icons/hi";
import { LuBiohazard, LuFileBarChart2, LuTags } from "react-icons/lu";
import {
  MdLogout,
  MdOutlineCalendarMonth,
  MdOutlineMasks,
  MdOutlinePersonalInjury,
} from "react-icons/md";
import {
  PiEyedropperSampleBold,
  PiGear,
  PiPillBold,
  PiVirusBold,
  PiWarningBold,
} from "react-icons/pi";
import { RiMessage2Line } from "react-icons/ri";
import { TbBed, TbBell, TbFileUpload, TbNotes } from "react-icons/tb";
import { CardItemType, Permission } from "./allTypes";

export const allPermissions: Record<
  Permission,
  {
    id: Permission;
    name: string;
    description: string;
    icon?: any;
    href?: string;
    type: "page" | "action" | "view";
  }
> = {
  usersPage: {
    id: "usersPage",
    name: "Utilisateurs",
    description: "",
    icon: <FiUsers size={18} />,
    href: "/admin/users",
    type: "page",
  },

  rolesPage: {
    id: "rolesPage",
    name: "Roles",
    description: "",
    icon: <LuTags size={18} />,
    href: "/admin/roles",
    type: "page",
  },

  referentielPage: {
    id: "referentielPage",
    name: "Referentiel",
    description: "",
    icon: <FaListUl size={16} />,
    href: "/admin/referentiel",
    type: "page",
  },

  configurationPage: {
    id: "configurationPage",
    name: "Configuration",
    description: "",
    icon: <PiGear size={22} />,
    href: "/admin/config",
    type: "page",
  },

  notificationsPage: {
    id: "notificationsPage",
    name: "Notifications",
    description: "",
    icon: <TbBell size={18} />,
    href: "/notifications",
    type: "page",
  },

  messageriePage: {
    id: "messageriePage",
    name: "Messagerie",
    description: "",
    icon: <RiMessage2Line size={18} />,
    href: "/messagerie",
    type: "page",
  },

  ecgDashboardPage: {
    id: "ecgDashboardPage",
    name: "Dashboard",
    description: "",
    icon: <FiPieChart size={18} />,
    href: "/dashboard",
    type: "page",
  },

  ecgsPage: {
    id: "ecgsPage",
    name: "ECGs",
    description: "",
    icon: <FaRegFolderOpen size={18} />,
    href: "/ecgs",
    type: "page",
  },

  ecgUsersPage: {
    id: "ecgUsersPage",
    name: "Utilisateurs",
    description: "",
    icon: <FiUsers size={18} />,
    href: "/ecgUsers",
    type: "page",
  },

  ecgProfilePage: {
    id: "ecgProfilePage",
    name: "Profil",
    description: "",
    icon: <CgProfile size={18} />,
    href: "/profile",
    type: "page",
  },

  loginPage: {
    id: "loginPage",
    name: "Déconnexion",
    description: "",
    icon: <MdLogout size={18} />,
    href: "/login",
    type: "page",
  },
};

export const allItemTypes: Record<
  CardItemType,
  {
    id: CardItemType;
    name: string;
    icon?: any;
    color: string;
    secondaryColor: string;
  }
> = {
  simple: {
    id: "simple",
    name: "Simple",
    icon: null,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  gray: {
    id: "gray",
    name: "Gris",
    icon: null,
    color: "rgba(0, 0, 0,0.8)",
    secondaryColor: "rgba(0, 0, 0,0.1)",
  },
  red: {
    id: "red",
    name: "Rouge",
    icon: null,
    color: "rgba(255, 0, 0,1)",
    secondaryColor: "rgba(255, 0, 0,0.1)",
  },
  green: {
    id: "green",
    name: "Vert",
    icon: null,
    color: "rgba(2, 194, 75, 1)",
    secondaryColor: "rgba(2, 194, 75,0.12)",
  },
  blue: {
    id: "blue",
    name: "Bleu",
    icon: null,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  orange: {
    id: "orange",
    name: "Orange",
    icon: null,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  purple: {
    id: "purple",
    name: "Violet",
    icon: null,
    color: "rgba(144, 0, 255,1)",
    secondaryColor: "rgba(144, 0, 255,0.1)",
  },
  pink: {
    id: "pink",
    name: "Rose",
    icon: null,
    color: "rgba(255, 77, 115,1)",
    secondaryColor: "rgba(255, 77, 115,0.1)",
  },

  white: {
    id: "white",
    name: "Blanc",
    icon: null,
    color: "rgba(255, 255, 255,1)",
    secondaryColor: "rgba(255, 255, 255,0.1)",
  },

  consultation: {
    id: "consultation",
    name: "Consultation",
    icon: <FaStethoscope />,
    color: "rgba(18, 129, 255,1)",
    secondaryColor: "rgba(0, 136, 255,0.1)",
  },

  evolution: {
    id: "evolution",
    name: "Mot d'évolution",
    icon: <TbNotes />,
    color: "rgba(18, 129, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  shift: {
    id: "shift",
    name: "Mot de la garde",
    icon: <TbNotes />,
    color: "rgba(18, 129, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  story: {
    id: "story",
    name: "Histoire de la maladie",
    icon: <FaStethoscope />,
    color: "rgba(18, 129, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },

  intervention: {
    id: "intervention",
    name: "Intervention",
    icon: <MdOutlinePersonalInjury />,
    color: "rgba(18, 129, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  ventilation: {
    id: "ventilation",
    name: "Ventilation",
    icon: <MdOutlineMasks />,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },

  initialExamination: {
    id: "initialExamination",
    name: "Examen clinique initial",
    icon: <HiOutlineDocumentText />,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },

  admission: {
    id: "admission",
    name: "Observation d'admission",
    icon: <HiOutlineDocumentText />,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  billet: {
    id: "billet",
    name: "Billet de salle",
    icon: <HiOutlineDocumentText />,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  avis: {
    id: "avis",
    name: "Avis",
    icon: <HiOutlineDocumentText />,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },

  consigne: {
    id: "consigne",
    name: "Consigne",
    icon: <FaStethoscope />,
    color: "rgba(144, 0, 255,1)",
    secondaryColor: "rgba(144, 0, 255,0.1)",
  },
  mesure: {
    id: "mesure",
    name: "Mesure",
    icon: <FaRegChartBar />,
    // color: "rgba(255, 77, 115,1)",
    // secondaryColor: "rgba(255, 77, 115,0.1)",
    color: "rgba(235, 34, 23,1)",
    secondaryColor: "rgba(235, 34, 23,0.1)",
  },
  score: {
    id: "score",
    name: "Score",
    icon: <LuFileBarChart2 />,
    color: "rgba(235, 34, 23,1)",
    secondaryColor: "rgba(235, 34, 23,0.1)",
  },
  upload: {
    id: "upload",
    name: "Fichier",
    icon: <TbFileUpload />,
    color: "rgba(255, 77, 115,1)",
    secondaryColor: "rgba(255, 77, 115,0.1)",
  },

  radiotherapy: {
    id: "radiotherapy",
    name: "Radiothérapie",
    icon: <LuBiohazard />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  surgery: {
    id: "surgery",
    name: "Chirurgie",
    icon: <MdOutlinePersonalInjury />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  metastasis: {
    id: "metastasis",
    name: "Métastase",
    icon: <PiVirusBold />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  toxicity: {
    id: "toxicity",
    name: "Toxicité",
    icon: <PiWarningBold />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  cure: {
    id: "cure",
    name: "Cure",
    icon: <BiInjection />,
    color: "rgba(144, 0, 255,1)",
    secondaryColor: "rgba(144, 0, 255,0.1)",
  },

  exam: {
    id: "exam",
    name: "Examen",
    icon: <BiTestTube />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  examDemand: {
    id: "examDemand",
    name: "Demande d'examen",
    icon: <BiTestTube />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },

  examStep: {
    id: "examStep",
    name: "Étape d'examen",
    icon: <BiTestTube />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  diagnostic: {
    id: "diagnostic",
    name: "Diagnostic",
    icon: <FaStethoscope />,
    color: "rgba(20, 150, 57,1)",
    secondaryColor: "rgba(20, 150, 57,0.1)",
  },
  appointment: {
    id: "appointment",
    name: "Rendez-vous",
    icon: <MdOutlineCalendarMonth />,
    color: "rgba(0, 136, 255,1)",
    secondaryColor: "rgba(0, 136, 255,0.1)",
  },

  document: {
    id: "document",
    name: "Document",
    icon: <CgFileDocument />,
    color: "rgba(2, 194, 75, 0.9)",
    secondaryColor: "rgba(2, 194, 75,0.1)",
  },
  ordonnance: {
    id: "ordonnance",
    name: "Ordonnance",
    icon: <PiPillBold />,
    color: "rgba(144, 0, 255,1)",
    secondaryColor: "rgba(144, 0, 255,0.1)",
  },
  hospitalisation: {
    id: "hospitalisation",
    name: "Hospitalisation",
    icon: <TbBed />,
    color: "rgba(0, 136, 255,1)",
    secondaryColor: "rgba(0, 136, 255,0.1)",
  },

  sample: {
    id: "sample",
    name: "Échantillon",
    icon: <PiEyedropperSampleBold />,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
};
